<template>
    <div class="c-shop-settings">
        <v-title title="Shop Configuration"></v-title>
        <template v-if="hasPermission('MerchantCentral/listShopConfig') && selectedShop.applicationId !== '0'">
            <title-box>
                <div class="row">
                    <div class="col-12">
                        <h1>Shop Configuration</h1>
                    </div>
                </div>
                <mercur-tabs class="c-shop-settings__tabs">
                    <mercur-tab title="General" :to="getShopConfigTab('ShopConfigGeneral')"></mercur-tab>
                    <mercur-tab title="Finance" :to="getShopConfigTab('ShopConfigFinance')"></mercur-tab>
                    <mercur-tab title="Orders" :to="getShopConfigTab('ShopConfigOrders')"></mercur-tab>
                    <mercur-tab title="Artwork" :to="getShopConfigTab('ShopConfigArtwork')"></mercur-tab>
                    <mercur-tab title="Content" :to="getShopConfigTab('ShopConfigContent')"></mercur-tab>
                    <mercur-tab title="Shipment" :to="getShopConfigTab('ShopConfigShipment')"></mercur-tab>
                    <mercur-tab title="Customer360" :to="getShopConfigTab('ShopConfigCustomer360')"></mercur-tab>
                    <mercur-tab title="Presta" :to="getShopConfigTab('ShopConfigPresta')"></mercur-tab>
                </mercur-tabs>
            </title-box>
            <div class="container-fluid mt-3">
                <router-view ref="routeview"></router-view>
            </div>
        </template>
        <template v-else-if="this.selectedShop.applicationId === '0'">
            <div class="container-fluid">
                <mercur-card class="mt-4 mb-4">
                    <p>Select a shop to see and edit config</p>
                </mercur-card>
            </div>
        </template>
        <template v-else>
            <div class="container-fluid">
                Not allowed to see this view
            </div>
        </template>
    </div>
</template>
<script>

export default {
    name: 'ShopConfigView',
    methods: {
        getShopConfigTab (name) {
            return this.$router.resolve(
                {
                    name: name,
                    params: {
                        ...this.$route.params,
                    },
                }
            ).href
        },
    },
}
</script>

<style lang="scss">
    .c-shop-settings {
        &__tabs {
            margin-bottom: -30px;
        }
    }
</style>
